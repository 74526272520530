import { assign, chain, cloneDeep, concat, filter, find, findIndex, findKey, flatMap, forEach, forOwn, get, isArray, isEmpty, isNil, isObject, keys, map, orderBy, pickBy, set, some, sumBy, uniq, uniqBy, values } from "lodash";
import moment from "moment-timezone";
import CustomError from "../CustomError";
import { Booking } from "../types/Booking";
import { FunctionBooking } from "../types/FunctionBooking";
import { BatchSetting, BeverageMenuPackage, CourseGroup, GroupHeading, GroupHeadingProduct, GroupHeadingProductSizes, GroupHeadingProducts, GroupHeadings, GroupInclusionSetupFromHeading, Menu, MenuInclusionSettingCountdown, MenuInclusionSettingCountdowns, MenuInclusionSettingsByCustomer, MenuInclusionSettingsByMenuHeading, MenuInclusions, MenuInclusionsType, MenuOrderingAllocationType, MenuOrderingStyle, Menus, Package, ProductOnlyLimitlessSetting } from "../types/Menu";
import { BookingOrderingData, OrderItem, OrderItemStatus, OrderItems, OrderSource, Orders, manualCourseGroups } from "../types/Order";
import { DisabledMenuHeadingIntervals, OpeningTimes } from "../types/Seating";
import { AdditionGroups, ComboGroup, ModifierGroups, PackageOverridePricesProduct, Product, StockLimitProducts, StockLimits, UpsellGroups } from "../types/product";
import { LineItemTypeId, PaymentSummary, Transaction } from "../types/transaction";
import { getEndTime, getNowByZoneId, getOpeningTimesAdjusted, getTodayByZoneId } from "../utils";
import { ProductCategoryId } from "./../types/ProductCategoryId";
import { isFunctionBooking, isStatusEqualByBooking } from "./bookingUtils";

export const findOrdersByCustomerId = (orders: Orders, customerId: string, bookingId?: string) => {
  const orderItems: OrderItems = {};
  forEach(
    pickBy(orders, (o) => o.customerId === customerId && (bookingId ? o.bookingId === bookingId : true)),
    (o) =>
      Object.assign(
        orderItems,
        pickBy(o.orderItems, (oi) => oi.inclusive)
      )
  );
  return orderItems;
};

export const findOrdersByGuestId = (orders: Orders, userId: string, bookingId?: string) => {
  const orderItems: OrderItems = {};
  forEach(
    pickBy(orders, (o) => o.customerId === userId && (bookingId ? o.bookingId === bookingId : true)),
    (o) =>
      Object.assign(
        orderItems,
        pickBy(o.orderItems, (oi) => oi.inclusive)
      )
  );
  return orderItems;
};
/**
 * Get MenuInclusionSettings by given [ids] where ids can be customerId or guestId to ensure compatibility between till and ordering app
 */
export const getMenuInclusionSettingByCustomers = (customerIds: string[], menu: Menu, booking: Booking | FunctionBooking, bookingId, orders: Orders, zoneId: string): [MenuInclusionSettingsByCustomer, MenuInclusionSettingCountdowns] => {
  const countdowns = {};
  const menuInclusionSettings: MenuInclusionSettingsByCustomer = {};

  customerIds.forEach((customerId) => {
    // get menuOption Id
    const isBookingCustomer = booking.customerId === customerId;
    const bookingGuest = find(booking.guests, (guest) => guest.customerId === customerId);
    const menuOptionId = isBookingCustomer ? booking.menuOptionId : bookingGuest?.menuOptionId;

    menuInclusionSettings[customerId] = {};
    countdowns[customerId] = [];
    if (menuOptionId && menu) {
      const groupHeadings = { ...menu.foodInclusions?.[menuOptionId]?.groupHeadings, ...menu.drinkInclusions?.[menuOptionId]?.groupHeadings };
      const orderItems: OrderItem[] = chain(orders)
        .pickBy((o) => o.bookingId === bookingId)
        .map((o) => map(o.orderItems, (oi) => ({ ...oi, customerId: o.customerId })))
        .flatten()
        .value();
      const [menuHeadingSetting, menuHeadingCountdown] = getMenuInclusionSetting(groupHeadings, orderItems, booking, customerId, zoneId, menu);
      menuInclusionSettings[customerId] = menuHeadingSetting;
      countdowns[customerId] = menuHeadingCountdown;
    }
  });
  return [menuInclusionSettings, countdowns];
};

const orderItemsForInclusionCheck = (orderItems: OrderItem[], groupHeading: GroupHeading, customerId: string, nestedGroupHeadingIds) => {
  let customerIdToCheck = "";
  if (groupHeading.fixedQuantityType === MenuOrderingAllocationType.PerPerson) {
    customerIdToCheck = customerId;
  }
  const orderItemsByCustomerId: OrderItem[] = customerId ? orderItems.filter((oi) => oi.customerId === customerId) : orderItems;
  const filteredOrderItems: OrderItem[] = customerIdToCheck ? orderItems.filter((oi) => oi.customerId === customerIdToCheck) : orderItems;
  const orderItemsFromGroupHeadingId: OrderItem[] = orderBy(filter(filteredOrderItems, (oi) => nestedGroupHeadingIds.includes(oi.groupHeadingId) && oi.inclusive && !oi.inclusiveProduct) as OrderItem[], ["time"], ["desc"]);
  const basketOrderItemsFromGroupHeadingId: OrderItem[] = orderBy(filter(orderItemsByCustomerId, (oi) => nestedGroupHeadingIds.includes(oi.groupHeadingId) && oi.inclusive && !oi.inclusiveProduct) as OrderItem[], ["time"], ["desc"]);
  const sentOrderItems = filter(orderItemsFromGroupHeadingId, (oi) => oi.inclusive && oi.orderStatus >= OrderItemStatus.WaitingToBeSent);
  const sentOrderItemsQuantity = sentOrderItems.reduce((sum, oi) => sum + oi.quantity, 0);
  const basketOrderItems = filter(basketOrderItemsFromGroupHeadingId, (oi) => oi.orderStatus < OrderItemStatus.WaitingToBeSent || oi.orderStatus === OrderItemStatus.InBasket);
  const basketItemsQuantity = basketOrderItems.reduce((sum, oi) => sum + oi.quantity, 0);
  return {
    orderItemsFromGroupHeadingId,
    sentOrderItems,
    sentOrderItemsQuantity,
    basketOrderItems,
    basketItemsQuantity,
  };
};

function getMenuInclusionSettingFromGroupHeading(
  booking: Booking | FunctionBooking, //
  customerId: string,
  countdowns: MenuInclusionSettingCountdown[],
  groupHeading: GroupHeading,
  groupHeadingId: string,
  orderItems: OrderItem[],
  menuInclusionSettings: MenuInclusionSettingsByMenuHeading,
  zoneId: string,
  menu: Menu,
  now?: number // use this arg to "freeze" now eg in context of preview menu, otherwise it defaults to getNowByZoneId(zoneId)
) {
  if (!booking || !groupHeading) return false;
  const nestedGroupHeadingIds = [groupHeadingId];

  const fillNestedGroupHeadingIds = (groupHeading) => {
    for (const groupHeadingId in groupHeading.groupHeadings) {
      const gh = groupHeading.groupHeadings[groupHeadingId];
      nestedGroupHeadingIds.push(groupHeadingId);
      fillNestedGroupHeadingIds(gh);
    }
  };
  fillNestedGroupHeadingIds(groupHeading);

  const { inclusionType, fixedQuantityType, inclusionQuantityLimit, perBookingQuantityLimits, inclusionMinutesLimit, displayName, menuHeadingId, onlyHostCanOrder } = groupHeading;
  const groupInclusionSetupFromHeading = {
    inclusionType,
    fixedQuantityType,
    inclusionQuantityLimit,
    perBookingQuantityLimits,
    inclusionMinutesLimit,
    displayName,
    menuHeadingId,
    onlyHostCanOrder: onlyHostCanOrder ?? false,
  };

  if (checkIfMenuHasLimitlessInclusion(menu)) {
    const timeLeftToOrderBeforeBookingEnd = getDiffFromBookingEnd(booking, zoneId, Number(menu.flags?.disableLimitlessOrderingBeforeBookingEnd || 0), now);
    if (timeLeftToOrderBeforeBookingEnd <= 0) {
      menuInclusionSettings[groupHeadingId] = {
        groupInclusionSetupFromHeading,
        limit: null,
        quantityLeft: 0,
        quantityOrdered: 0,
        limitReached: true,
      };
      return false;
    }
  }

  if (groupHeading.inclusionType === MenuInclusionsType.Limitless && groupHeading.fixedQuantityType) {
    if (groupHeading.fixedQuantityType === MenuOrderingAllocationType.PerBooking && onlyHostCanOrder && booking.customerId !== customerId) return true;

    const { orderItemsFromGroupHeadingId, sentOrderItems, basketOrderItems, basketItemsQuantity } = orderItemsForInclusionCheck(orderItems, groupHeading, customerId, nestedGroupHeadingIds);
    const { limit, timeLimit } = getTimeAndLimit(groupInclusionSetupFromHeading, zoneId, booking);
    if (orderItemsFromGroupHeadingId.length > 0) {
      const basketOrderItemQuantity = basketOrderItems.reduce((sum, oi) => sum + oi.quantity, 0);
      const time = Date.now();
      // Consider ignoreLimitlessConstraintUntil flag once booking is seated
      if (menu.flags) {
        const { ignoreLimitlessConstraintUntil } = menu.flags;
        const timeSinceSeated = booking.statusChangeData?.Seated ? getMilliSecondsSinceTicks(zoneId, booking.statusChangeData.Seated) : undefined;
        if (ignoreLimitlessConstraintUntil && timeSinceSeated && timeSinceSeated < Number(ignoreLimitlessConstraintUntil) * 60 * 1000) {
          menuInclusionSettings[groupHeadingId] = {
            groupInclusionSetupFromHeading,
            limit: limit ?? null,
            quantityLeft: limit ? limit - basketOrderItemQuantity : null,
            quantityOrdered: 0,
            limitReached: limit ? limit <= basketOrderItemQuantity : false,
          };
          return true;
        }
      }
      let resetTime = sentOrderItems[0]?.time;
      if (timeLimit === null || timeLimit === undefined) throw new CustomError(`required timeLimit for ${groupHeading.displayName || ""} is not set.`, 400);
      while (resetTime < time) resetTime += timeLimit;
      const sentItemsWithinLastTimeFrame = [];
      sentOrderItems.forEach((oi) => oi.time >= resetTime - timeLimit && oi.time <= resetTime && sentItemsWithinLastTimeFrame.push(oi));
      const sentItemsQuantity = sentItemsWithinLastTimeFrame.reduce((sum, oi) => sum + oi.quantity, 0);
      // const totalQuantity = sentItemsQuantity;
      if (limit <= sentItemsQuantity) countdowns.push({ groupHeadingId, resetTime });
      menuInclusionSettings[groupHeadingId] = {
        groupInclusionSetupFromHeading,
        limit: limit,
        quantityLeft: limit - sentItemsQuantity - basketItemsQuantity,
        quantityOrdered: sentItemsQuantity,
        limitReached: limit <= sentItemsQuantity + basketItemsQuantity,
      };
    } else
      menuInclusionSettings[groupHeadingId] = {
        groupInclusionSetupFromHeading,
        limit: limit,
        quantityLeft: limit,
        quantityOrdered: 0,
        limitReached: limit <= 0,
      };
    return true;
  } else if (groupHeading.inclusionType === MenuInclusionsType.Limitless) {
    const { sentOrderItemsQuantity } = orderItemsForInclusionCheck(orderItems, groupHeading, customerId, nestedGroupHeadingIds);
    menuInclusionSettings[groupHeadingId] = {
      groupInclusionSetupFromHeading,
      quantityLeft: null,
      quantityOrdered: sentOrderItemsQuantity,
      limitReached: false,
      limit: null,
    };
    return true;
  } else if (groupHeading.inclusionType === MenuInclusionsType.Fixed && groupHeading.fixedQuantityType) {
    if (groupHeading.fixedQuantityType === MenuOrderingAllocationType.PerPerson) {
      const { sentOrderItemsQuantity, basketItemsQuantity } = orderItemsForInclusionCheck(orderItems, groupHeading, customerId, nestedGroupHeadingIds);
      // const totalQuantity = orderItemsFromGroupHeadingId.reduce((sum, oi) => sum + oi.quantity, 0);
      const fixedLimit = Number(groupHeading.inclusionQuantityLimit);
      menuInclusionSettings[groupHeadingId] = {
        groupInclusionSetupFromHeading,
        limit: fixedLimit,
        quantityLeft: fixedLimit - sentOrderItemsQuantity - basketItemsQuantity,
        quantityOrdered: sentOrderItemsQuantity,
        limitReached: fixedLimit <= sentOrderItemsQuantity + basketItemsQuantity,
      };
    } else if (groupHeading.fixedQuantityType === MenuOrderingAllocationType.PerBooking) {
      // if (onlyHostCanOrder && booking.customerId !== customerId) return false;
      const { sentOrderItemsQuantity, basketItemsQuantity } = orderItemsForInclusionCheck(orderItems, groupHeading, customerId, nestedGroupHeadingIds);
      // const totalQuantity = orderItemsFromGroupHeadingId.reduce((sum, oi) => sum + oi.quantity, 0);
      const totalBookingPax = booking.pax + (booking.extraPax || 0) - (booking.children || 0);
      const limitForThisBooking = !booking ? null : groupHeading.perBookingQuantityLimits.find((pbl) => Number(pbl.minPax) <= Number(totalBookingPax) && Number(pbl.maxPax) >= Number(totalBookingPax));
      const fixedLimit = limitForThisBooking?.quantityLimit || 0;
      menuInclusionSettings[groupHeadingId] = {
        groupInclusionSetupFromHeading,
        limit: fixedLimit,
        quantityLeft: fixedLimit - sentOrderItemsQuantity - basketItemsQuantity,
        quantityOrdered: sentOrderItemsQuantity,
        limitReached: fixedLimit <= sentOrderItemsQuantity + basketItemsQuantity,
      };
    }
    return true;
  }
  return false;
}

export function getMenuInclusionSetting(groupHeadings: GroupHeadings, orderItems: OrderItem[], booking: Booking | FunctionBooking, customerId, zoneId: string, menu: Menu, now?: number): [MenuInclusionSettingsByMenuHeading, MenuInclusionSettingCountdown[]] {
  const countdowns: MenuInclusionSettingCountdown[] = [];
  const menuInclusionSettings: MenuInclusionSettingsByMenuHeading = {};
  const fillSettingRecursively = (groupHeadings) => {
    for (const groupHeadingId in groupHeadings) {
      const found = getMenuInclusionSettingFromGroupHeading(booking, customerId, countdowns, groupHeadings[groupHeadingId], groupHeadingId, orderItems, menuInclusionSettings, zoneId, menu, now);
      if (!found && groupHeadings[groupHeadingId].groupHeadings) {
        fillSettingRecursively(groupHeadings[groupHeadingId].groupHeadings);
      }
    }
  };
  if (booking && groupHeadings) fillSettingRecursively(groupHeadings);
  return [menuInclusionSettings, countdowns];
}

const getPackageGroupHeadingsOverridePricesProduct = (groupHeadingsSetup: GroupHeading, overridePricing) => {
  for (const key in groupHeadingsSetup) {
    if (key === "products") {
      const products = groupHeadingsSetup.products;
      for (const productId in products) {
        const productSizes = groupHeadingsSetup.products[productId].productSizeIds;
        for (const sizeId in productSizes) {
          const price = groupHeadingsSetup.products[productId].productSizeIds[sizeId]?.upgradePrice;
          if (Number(price) >= 0)
            overridePricing = {
              ...overridePricing,
              [productId]: { ...overridePricing[productId], [sizeId]: Number(price) },
            };
        }
      }
    } else if (key === "groupHeadings") {
      const groupHeadings = groupHeadingsSetup.groupHeadings;
      for (const groupId in groupHeadings) {
        getPackageGroupHeadingsOverridePricesProduct(groupHeadings[groupId], overridePricing);
      }
    }
  }
  return overridePricing;
};

export const getPackageMenuOverridePricesProduct = (menuHeadingSetup: GroupHeading, overridePricing = {}) => {
  const updatedMenuHeadings = { ...menuHeadingSetup };
  for (const key in updatedMenuHeadings) {
    if (key === "additional") {
      const packageMenuAdditionalItems = menuHeadingSetup.additional?.isEnabled ? menuHeadingSetup.additional?.products : null;
      for (const productId in packageMenuAdditionalItems) {
        for (const sizeId in packageMenuAdditionalItems[productId]?.productSizeIds) {
          const price = packageMenuAdditionalItems[productId].productSizeIds[sizeId].upgradePrice;
          if (Number(price) >= 0)
            overridePricing = {
              ...overridePricing,
              [productId]: { ...overridePricing[productId], [sizeId]: Number(price) },
            };
        }
      }
    }
    if (key === "special") {
      const packageMenuAdditionalItems = menuHeadingSetup.special?.isEnabled ? menuHeadingSetup.special?.products : null;
      for (const productId in packageMenuAdditionalItems) {
        for (const sizeId in packageMenuAdditionalItems[productId]?.productSizeIds) {
          overridePricing = {
            ...overridePricing,
            [productId]: { ...overridePricing[productId], [sizeId]: 0 },
          };
        }
      }
    }

    if (key === "products") {
      const products = updatedMenuHeadings.products;
      for (const productId in products) {
        const productSizes = updatedMenuHeadings.products[productId].productSizeIds;
        for (const sizeId in productSizes) {
          const price = updatedMenuHeadings.products[productId].productSizeIds[sizeId]?.upgradePrice;
          if (Number(price) >= 0)
            overridePricing = {
              ...overridePricing,
              [productId]: { ...overridePricing[productId], [sizeId]: Number(price) },
            };
        }
      }
    }
    if (key === "groupHeadings") {
      const groupHeadings = updatedMenuHeadings.groupHeadings;
      for (const groupId in groupHeadings) {
        overridePricing = getPackageGroupHeadingsOverridePricesProduct(groupHeadings[groupId], overridePricing);
      }
    }
  }

  return overridePricing as {
    [productId: string]: { [sizeId: string]: number };
  };
};

export const getPackageOverridePricesProduct = (_package: Package = null, beverageMenu: BeverageMenuPackage = null) => {
  const menuSetup = _package?.food || _package?.beverage || _package?.foodbeverage || beverageMenu?.beverageMenus;
  let overridePricing: PackageOverridePricesProduct = {};
  forEach(menuSetup?.groupHeadings, (mh) => {
    overridePricing = {
      ...overridePricing,
      ...getPackageMenuOverridePricesProduct(mh),
    };
  });
  return overridePricing;
};

export function getTimeAndLimit(groupInclusionSetupFromHeading: GroupInclusionSetupFromHeading, zoneId: string, booking: Booking | FunctionBooking, menu?: Menu) {
  let limit = null;
  let timeLimit = null;
  if (booking && !isEmpty(groupInclusionSetupFromHeading)) {
    const { fixedQuantityType, inclusionQuantityLimit, inclusionMinutesLimit, perBookingQuantityLimits, inclusionType } = groupInclusionSetupFromHeading;
    if (!fixedQuantityType) return { limit, timeLimit };
    if (fixedQuantityType === "perPerson") {
      limit = Number(inclusionQuantityLimit);
      timeLimit = Number(inclusionMinutesLimit) * 60 * 1000;
    } else {
      const totalBookingPax = booking.pax + (booking.extraPax || 0) - (booking.children || 0);
      const perBookingLimit = !booking ? null : find(perBookingQuantityLimits, (pbl) => Number(pbl.minPax) <= Number(totalBookingPax) && Number(pbl.maxPax) >= Number(totalBookingPax));
      limit = perBookingLimit?.quantityLimit ? +perBookingLimit.quantityLimit : null;
      timeLimit = perBookingLimit?.minutes ? +perBookingLimit.minutes * 60 * 1000 : null;
    }

    // Conditionally override timeLimit
    if (shouldIgnoreLimitlessConstraint(inclusionType, zoneId, menu, booking)) timeLimit = null;
  }
  return { limit, timeLimit };
}

export function getAllGroupHeadingProductsFromMenu(menu: Menu, menuOptionId?: string) {
  const products: GroupHeadingProducts = {};
  if (menu.food) {
    assign(products, getAllProductsFromGroupHeadings(menu.food.groupHeadings));
  }
  if (menu.beverage) {
    assign(products, getAllProductsFromGroupHeadings(menu.beverage.groupHeadings));
  }
  if (menu.foodInclusions?.[menuOptionId]) {
    assign(products, getAllProductsFromGroupHeadings(menu.foodInclusions[menuOptionId].groupHeadings));
  }
  if (menu.drinkInclusions?.[menuOptionId]) {
    assign(products, getAllProductsFromGroupHeadings(menu.drinkInclusions[menuOptionId].groupHeadings));
  }
  return products;
}

export function getAllProductsFromGroupHeadings(groupHeadings: GroupHeadings): GroupHeadingProducts {
  const products: GroupHeadingProducts = {};
  for (const groupHeadingId in groupHeadings) {
    getAllProductsFromGroupHeading(groupHeadings[groupHeadingId], products);
  }
  return products;
}

export function validateMenuHaveProduct(productId: string, productSizeId: string, menu: Menu, specialsStockLimitProducts: StockLimitProducts, addedAsUpsell = false): string {
  if (addedAsUpsell) return "";
  const groups: GroupHeadings = {};
  for (const moi in menu.foodInclusions) {
    Object.assign(groups, menu.foodInclusions[moi].groupHeadings);
  }
  for (const moi in menu.drinkInclusions) {
    Object.assign(groups, menu.drinkInclusions[moi].groupHeadings);
  }
  Object.assign(groups, menu.food?.groupHeadings);
  Object.assign(groups, menu.beverage?.groupHeadings);
  for (const groupId1 in groups) {
    const group = groups[groupId1] as GroupHeading;
    for (const groupId1 in group.groupHeadings) {
      const gh = group.groupHeadings[groupId1];
      for (const groupId2 in gh.groupHeadings) {
        const gh1 = gh.groupHeadings[groupId2];
        const groupHeadingProducts = getAllProductsFromGroupHeading(gh1, {});
        if (groupHeadingProducts[productId]) return "";
      }
      const groupHeadingProducts = getAllProductsFromGroupHeading(gh, {});
      if (groupHeadingProducts[productId]) return "";
    }
    const groupHeadingProducts = getAllProductsFromGroupHeading(group, {});
    if (groupHeadingProducts[productId]) return "";
  }
  // Check if this is one of special product
  if (specialsStockLimitProducts?.[productId]?.[productSizeId]?.menus?.[menu.id]) return "";
  return "This product is currently unavailable. Please remove it from your basket.";
}

export function getAllProductsFromGroupHeading(groupHeading: GroupHeading, products: GroupHeadingProducts) {
  if (keys(groupHeading.groupHeadings).length > 0) {
    for (const groupHeadingId in groupHeading.groupHeadings) {
      getAllProductsFromGroupHeading(groupHeading.groupHeadings[groupHeadingId], products);
    }
  } else if (keys(groupHeading.products).length > 0) {
    assign(products, groupHeading.products);
  }
  if (groupHeading.additional?.isEnabled && groupHeading.additional.products) {
    assign(products, groupHeading.additional.products);
  }
  if (groupHeading.special?.isEnabled && groupHeading.special.products) {
    assign(products, groupHeading.special.products);
  }
  return products;
}

/**
 *
 * @param groupHeading
 * @param groupHeadingProducts
 * @returns group heading products with duplicate products as well
 */
export function getAllProductsFromGroupHeadingInArray(groupHeading: GroupHeading, groupHeadingProducts: GroupHeadingProduct[] = []) {
  if (keys(groupHeading.groupHeadings).length > 0) {
    for (const groupHeadingId in groupHeading.groupHeadings) {
      getAllProductsFromGroupHeadingInArray(groupHeading.groupHeadings[groupHeadingId], groupHeadingProducts);
    }
  } else if (keys(groupHeading.products).length > 0) {
    groupHeadingProducts.push(...map(groupHeading.products, (p, _key) => ({ ...p, _key })));
  }
  if (groupHeading.additional?.isEnabled && groupHeading.additional.products) {
    groupHeadingProducts.push(...map(groupHeading.products, (p, _key) => ({ ...p, _key })));
  }
  if (groupHeading.special?.isEnabled && groupHeading.special.products) {
    groupHeadingProducts.push(...map(groupHeading.products, (p, _key) => ({ ...p, _key })));
  }
  return groupHeadingProducts;
}

export function getLandingPageProducts(menu: Menu, allProducts: Product[]) {
  const menuGroupHeadings = { ...menu.food.groupHeadings, ...menu.beverage.groupHeadings };
  const groupHeadingProducts = [];
  for (const groupHeadingId in menuGroupHeadings) {
    groupHeadingProducts.push(...getAllProductsFromGroupHeadingInArray(menuGroupHeadings[groupHeadingId], []));
  }
  const products = chain(groupHeadingProducts)
    .filter((ghp) => ghp?.landingPage)
    .map((p) => {
      const product = find(allProducts, (product) => product.id === p._key);
      return product;
    })
    .sort((a, b) => {
      const orderA = menu.landing?.products?.[a.id]?.order;
      const orderB = menu.landing?.products?.[b.id]?.order;
      if (orderA !== undefined && orderB !== undefined) {
        return orderA - orderB;
      }
      return 0;
    })
    .value();
  return products;
}

export function getImagesFromProduct(product: Product, publicStorageBucket: string, clientId: string) {
  let thumbnail = "";
  let image = "";
  if (product.image) {
    thumbnail = `https://storage.googleapis.com/${publicStorageBucket}/${clientId}/products/${product.image.fileName}-thumb.${product.image.ext}`;
    image = `https://storage.googleapis.com/${publicStorageBucket}/${clientId}/products/${product.image.fileName}.${product.image.ext}`;
  }
  return { thumbnail, image };
}

function getAllProductsAndGroupHeadingInformation(
  groupHeadings: GroupHeadings,
  stockLimits?: StockLimits,
  menuId?: string
): {
  products: GroupHeadingProducts;
  groupHeadingId: string;
}[] {
  const results: { products: GroupHeadingProducts; groupHeadingId: string }[] = [];
  for (const groupHeadingId in groupHeadings) {
    results.push({
      products: assign({}, getAllProductsFromGroupHeading(groupHeadings[groupHeadingId], {}), getGroupHeadingProductsFromSpecialProducts(groupHeadings[groupHeadingId].menuHeadingId, stockLimits?.specials, menuId)),
      groupHeadingId,
    });
  }
  return results;
}

export function getGroupHeadingProductsFromSpecialProducts(menuHeadingId: string, specials: StockLimitProducts, menuId: string): GroupHeadingProducts {
  const products: GroupHeadingProducts = {};
  for (const productId in specials) {
    const specialsByMenuHeadingId = pickBy(specials[productId], (s) => s.menus?.[menuId]?.menuHeadingId === menuHeadingId);
    for (const sizeId in specialsByMenuHeadingId) {
      const sizes: GroupHeadingProductSizes = {
        [sizeId]: {
          enabled: true,
          upgradePrice: specialsByMenuHeadingId[sizeId].menus[menuId].price,
        },
      };
      products[productId] = {
        _key: productId,
        note: specialsByMenuHeadingId[sizeId].menus[menuId].badgeText,
        productSizeIds: sizes,
      } as GroupHeadingProduct;
    }
  }
  return products;
}

export function getAllProductsAndGroupHeadingsFromMenu(
  menu: Menu,
  menuOptionId?: string,
  stockLimits?: StockLimits
): {
  products: GroupHeadingProducts;
  groupHeadingId: string;
}[] {
  let results: { products: GroupHeadingProducts; groupHeadingId: string }[] = [];
  if (menu?.food) {
    results = results.concat(getAllProductsAndGroupHeadingInformation(menu.food.groupHeadings, stockLimits, menu.id));
  }
  if (menu?.beverage) {
    results = results.concat(getAllProductsAndGroupHeadingInformation(menu.beverage.groupHeadings, stockLimits, menu.id));
  }
  if (menu?.foodInclusions?.[menuOptionId]) {
    results = results.concat(getAllProductsAndGroupHeadingInformation(menu.foodInclusions[menuOptionId].groupHeadings, stockLimits, menu.id));
  }
  if (menu?.drinkInclusions?.[menuOptionId]) {
    results = results.concat(getAllProductsAndGroupHeadingInformation(menu.drinkInclusions[menuOptionId].groupHeadings, stockLimits, menu.id));
  }
  return results;
}

/**
 * Updates a specific property for a product within the menu.
 * @param {Menu} menu - The menu object.
 * @param {string} targetProductId - The ID of the product to be updated.
 * @param {string} property - The name of the property to update.
 * @param {string|boolean} value - The new value for the property.
 * @returns {Menu} The updated menu object.
 */
export const updateProductPropertyInMenu = (menu: Menu, targetProductId: string, property: string, value: string | boolean): Menu => {
  const updateProperty = (groupHeadings: GroupHeadings) => {
    forOwn(groupHeadings, (groupHeading) => {
      if (groupHeading.groupHeadings) {
        updateProperty(groupHeading.groupHeadings);
      }

      if (groupHeading.products && groupHeading.products[targetProductId]) {
        groupHeading.products[targetProductId][property] = value;
      }
    });
  };

  if (menu.food && menu.food.groupHeadings) {
    updateProperty(menu.food.groupHeadings);
  }

  if (menu.beverage && menu.beverage.groupHeadings) {
    updateProperty(menu.beverage.groupHeadings);
  }

  return menu;
};

/**
 * Checks if the landing page is enabled for the specified product within the menu.
 * @param {Menu} menu - The menu object.
 * @param {string} productId - The ID of the product to check for landing page.
 * @returns {boolean} True if any product has the landing page enabled, otherwise false.
 */
export const isProductLandingPageEnabled = (menu: Menu, productId: string): boolean => {
  const hasMatchingProductInGroupHeadings = (groupHeadings) => {
    return some(groupHeadings, (groupHeading) => {
      const products = getAllProductsFromGroupHeadingInArray(groupHeading, []);
      return some(products, (p) => p._key === productId && p?.landingPage === true);
    });
  };

  return (menu.food && hasMatchingProductInGroupHeadings(menu.food.groupHeadings)) || (menu.beverage && hasMatchingProductInGroupHeadings(menu.beverage.groupHeadings));
};

/**
 * Generates a deposit payment message based on given adult/ child deposit amount.
 * @param {number} adultDepositAmount - The deposit amount for adults.
 * @param {number} childDepositAmount - The deposit amount for children.
 * @return {string|null} The payment message indicating the required deposit amount(s), or null if no deposit is required.
 */
export const generateDepositPaymentMessage = (adultDepositAmount: number, childDepositAmount: number): string => {
  const message: string[] = [];
  if (adultDepositAmount > 0) message.push(`$${adultDepositAmount}p/adult`);
  if (childDepositAmount > 0) message.push(`$${childDepositAmount}p/child`);
  return message.length ? `A deposit of ${message.join(" and ")} is required` : null;
};

export const checkIfMenuHasLimitlessInclusion = (menu: Menu): boolean => {
  const checkInclusionsForLimitlessOption = (inclusions) => {
    if (inclusions) {
      const productIds = keys(inclusions);
      return some(productIds, (productId) => {
        const groupHeadings: GroupHeadings = inclusions[productId].groupHeadings;
        return checkForLimitless(groupHeadings);
      });
    }
    return false;
  };

  // Check inclusionType recursively
  const checkForLimitless = (groupHeadings) => {
    if (groupHeadings) {
      const headingKeys = keys(groupHeadings);
      if (some(headingKeys, (key) => groupHeadings[key].inclusionType === MenuInclusionsType.Limitless)) {
        return true;
      }
      return some(headingKeys, (key) => {
        const subGroupHeadings = groupHeadings[key].groupHeadings;
        return checkForLimitless(subGroupHeadings);
      });
    }
    return false;
  };

  return checkInclusionsForLimitlessOption(menu.drinkInclusions) || checkInclusionsForLimitlessOption(menu.foodInclusions);
};

const getMilliSecondsSinceTicks = (zoneId: string, ticks: number) => {
  const now = moment.tz(zoneId);
  const millisecondsFromMidnight = (now.hours() * 60 + now.minutes()) * 60 * 1000;
  // const totalSeconds = Math.floor(ticks / 1000); // Convert to seconds
  return millisecondsFromMidnight - ticks;
  // const hours = Math.floor(totalSeconds / 3600); // 3600 seconds in an hour
  // const minutes = Math.floor((totalSeconds % 3600) / 60); // Remaining seconds converted to minutes
  // now.add(-totalSeconds, 'seconds');
  // const todayMidnight = moment(now).startOf("day").valueOf();
  // const tickTime = todayMidnight + ticks; // Calculate the time in milliseconds for the tick input
  // return now.valueOf() - tickTime; // Elapsed time in milliseconds
};

/**
 * Check if Limitless constraint should be ignored based on given inclusion type, menu and booking
 */
export const shouldIgnoreLimitlessConstraint = (inclusionType: MenuInclusionsType, zoneId: string, menu: Menu, booking: Booking | FunctionBooking) => {
  if (inclusionType !== MenuInclusionsType.Limitless) return true;
  const ignoreLimitlessConstraintUntil = menu?.flags?.ignoreLimitlessConstraint && menu.flags?.ignoreLimitlessConstraintUntil ? Number(menu.flags.ignoreLimitlessConstraintUntil) : undefined;
  const timeSinceSeated = booking.statusChangeData?.Seated ? getMilliSecondsSinceTicks(zoneId, booking.statusChangeData.Seated) : undefined;
  return ignoreLimitlessConstraintUntil && timeSinceSeated && timeSinceSeated < ignoreLimitlessConstraintUntil * 60 * 1000;
};

export const getMenuOptionIdByUserId = (userId: string, booking: Booking | FunctionBooking): string => {
  if (!booking) return "";
  if (!userId) return booking.menuOptionId;

  const isBookingCustomer = booking.customerId === userId;
  const bookingGuest = find(booking.guests, (guest) => guest.customerId === userId);
  return isBookingCustomer || isFunctionBooking(booking) ? booking.menuOptionId : bookingGuest?.menuOptionId;
};

/**
 * Retrieves disabled menu heading IDs within specified booking period.
 */
export const getDisabledMenuHeadingIntervals = (intervals: DisabledMenuHeadingIntervals, startTime: number, endTime?: number, intervalUnitInMinutes = 15) => {
  const disabledHeadingIds = {};
  if (isEmpty(intervals)) return disabledHeadingIds;
  for (const intervalId in intervals) {
    if (Number(intervalId) > startTime - intervalUnitInMinutes * 60 * 1000 && Number(intervalId) <= (endTime || startTime)) {
      const groupHeadingIds = intervals[intervalId].menuHeadingIds;
      for (const groupHeadingId in groupHeadingIds) {
        const groupHeading = groupHeadingIds[groupHeadingId];
        if (groupHeading.disabled) set(disabledHeadingIds, groupHeadingId, concat(get(disabledHeadingIds, groupHeadingId, []), intervalId));
        if (keys(groupHeading.subHeadingIds).length) {
          for (const subMenuHeadingId in groupHeading.subHeadingIds) {
            if (groupHeading.subHeadingIds[subMenuHeadingId]) set(disabledHeadingIds, subMenuHeadingId, concat(get(disabledHeadingIds, subMenuHeadingId, []), intervalId));
          }
        }
      }
    }
  }
  return disabledHeadingIds;
};

export const isServiceDisabled = (intervals: string[], timeStampNow: number, intervalUnitInMinutes = 15): boolean => {
  return some(intervals, (interval) => Number(interval) <= timeStampNow && Number(interval) > timeStampNow - intervalUnitInMinutes * 60 * 1000);
};

export const findNextDisabledInterval = (intervals: string[], timeStampNow: number, intervalUnitInMinutes = 15) => {
  return find(intervals, (intervalId) => Number(intervalId) > timeStampNow && Number(intervalId) <= timeStampNow + intervalUnitInMinutes * 60 * 1000);
};

export const getServiceStatusByGroupHeadingId = (intervals: DisabledMenuHeadingIntervals, groupHeadingId: string, booking: Booking | FunctionBooking, timestampNow: number, intervalUnitInMinutes = 15) => {
  const result = {
    isServiceDisabled: false, // if service during disabled interval
    serviceWillEndAt: null, // intervalId if service ends at this interval
  };
  if (isEmpty(intervals) || !booking) return result;
  const serviceStatusCheckStartTime = isStatusEqualByBooking(booking, "seated") ? timestampNow : booking.intervalId;
  const serviceStatusCheckEndTime = isStatusEqualByBooking(booking, "seated") ? timestampNow + booking.alg.duration * 60 * 1000 : getEndTime(booking);
  const disabledHeadingIds = getDisabledMenuHeadingIntervals(intervals, serviceStatusCheckStartTime, serviceStatusCheckEndTime);
  if (!isEmpty(disabledHeadingIds)) {
    const disabledIntervalsForThisHeading = get(disabledHeadingIds, groupHeadingId);
    if (!disabledIntervalsForThisHeading) return result;
    result.isServiceDisabled = isServiceDisabled(disabledIntervalsForThisHeading, timestampNow, intervalUnitInMinutes);
    result.serviceWillEndAt = result.serviceWillEndAt = findNextDisabledInterval(disabledIntervalsForThisHeading, timestampNow, intervalUnitInMinutes);
  }
  return result;
};

export const getUnpaidPrepaidMenusCustomerId = (bookings: (Booking | FunctionBooking)[], bookingId: string, transactions: Transaction[]): { [bookingId: string]: number } => {
  const unpaidPrepaidMenusCustomerIds: { [bookingId: string]: number } = {};
  const bookings1: (Booking | FunctionBooking)[] = filter(bookings, (b) => !bookingId || b._key === bookingId);
  for (let i = 0; i < bookings1.length; i++) {
    const bookingTransactions = filter(transactions, (t) => t.bookingId === bookings1[i]._key);
    const lineItems = chain(bookingTransactions)
      .map((t) => t.lineItems)
      .flatten()
      .value();
    const totalPaidMenus = chain(lineItems)
      .filter((li) => li.productCategoryId === ProductCategoryId.PrepaidMenus || li.lineItemTypeId === LineItemTypeId.Menu)
      .sumBy((li) => li.quantity)
      .value();
    const totalConfirmedNum = chain(lineItems)
      .filter((li) => li.productCategoryId === ProductCategoryId.SaleProducts)
      .groupBy((li) => li.customerId)
      .keys()
      .value().length;
    if (totalConfirmedNum > totalPaidMenus && (bookings1[i].paymentSummary as PaymentSummary)?.requiredPaymentTypeId > 1 && !bookings1[i].alg.onHold) {
      unpaidPrepaidMenusCustomerIds[bookings1[i]._key] = totalConfirmedNum - totalPaidMenus;
    }
  }
  return unpaidPrepaidMenusCustomerIds;
};

/**
 * Return timestamp based on booking
 * @param booking
 * @param zoneId
 * @param openingTimes
 * @param intervalUnitInMinutes
 * @returns
 */
export const getTimeToCheckServiceStatus = (booking: Booking | FunctionBooking, zoneId: string, openingTimes: OpeningTimes, intervalUnitInMinutes = 15, now?: number) => {
  if (!booking || !openingTimes) return getNowByZoneId(zoneId);

  // Also factor scenario when seated booking continues on to next day, eg 11pm - 1am next day
  if (!now) now = getNowByZoneId(zoneId) + moment.tz(zoneId).diff(moment(String(booking.date), "YYYYMMDD"), "days") * 24 * 60 * 60 * 1000;

  if (isStatusEqualByBooking(booking, "seated")) {
    // freeze now if seated outside of meal period intervals
    const { open, close } = getOpeningTimesAdjusted(openingTimes.open, openingTimes.close);

    // note last interval in Menu Heading Availability setup for given meal period is "close time" - "interval unit in minutes" and not the acutal "close time"
    return now <= Number(open) ? Number(open) : now >= Number(close) ? Number(close) - intervalUnitInMinutes * 60 * 1000 : now;
  }

  return booking.intervalId;
};

/**
 *
 * @param menu Menu
 * @return All course groups inside batch settings for menu in ordered list
 */
export const getBatchSettingsCourseGroups = (menu: Menu) => {
  const coursesInOrder: CourseGroup[] = [];
  if (menu?.orderingStyle !== MenuOrderingStyle.FixedOrderOfService) return coursesInOrder;

  const sortedFixedOrderBatchSettings: BatchSetting[] = chain(menu.batchSettings || {})
    .pickBy((bs: BatchSetting) => bs.orderingStyle === MenuOrderingStyle.FixedOrderOfService)
    .map((bs, id) => ({ ...bs, id }))
    .orderBy(["order"], ["asc"])
    .value();
  sortedFixedOrderBatchSettings.forEach((batchSetting) => {
    const sortedCourseGroups: CourseGroup[] = orderBy(
      map(batchSetting.courseGroups, (cg, _key) => ({ ...cg, _key })),
      ["order"],
      ["asc"]
    );
    coursesInOrder.push(...sortedCourseGroups.map((cg) => ({ ...cg, _key: cg._key })));
  });

  return coursesInOrder;
};

/**
 *
 * @param menu menu selected on till
 * @param linkedBooking booking linked to till
 * @return course groups that needs to be used for till
 */
export const getOrderingCourseGroupsToUse = (menu: Menu = null, forBooking = false) => {
  const menuCourseGroups: CourseGroup[] = forBooking && menu ? getBatchSettingsCourseGroups(menu) : [];
  const courseGroupsToDisplay: CourseGroup[] = menuCourseGroups.length === 0 ? [...manualCourseGroups] : menuCourseGroups;
  return courseGroupsToDisplay;
};

/**
 * Finds Next Course Group Data
 */
export const getOrderingNextCourseGroupData = (menu: Menu, bookingId = "", functionBookingId = "", bookingOrderingData: BookingOrderingData, orders: Orders, nextCourseBasedOnOrderStatus: boolean) => {
  const courseGroups: CourseGroup[] = getOrderingCourseGroupsToUse(menu, bookingId ? true : false);
  const sentOrderItemsWithCourseGroup = chain(orders)
    .pickBy((o) => (bookingId ? o.bookingId === bookingId : o.functionBookingId === functionBookingId))
    .flatMap((o) => map(o.orderItems, (oi) => ({ ...oi })))
    .flatMap((oi) => (shouldUseComboChildItemsSeparately(oi) ? map(oi.comboGroup, (oi) => oi) : oi))
    .filter((oi) => !!oi.courseGroupKey && oi.orderStatus >= OrderItemStatus.Sent)
    .value();
  const courseGroupsWithSentItems: CourseGroup[] = courseGroups.filter((cg) => sentOrderItemsWithCourseGroup.find((oi) => oi.courseGroupKey === cg._key) !== undefined);

  let nextCourseKey = "";
  let newPreviousOrderingCourseAway = "";
  const { currentOrderingCourseAway = "", previousOrderingCourseAway = "" } = bookingOrderingData?.nextCourseAwayData || {};

  // Find next course away based upon user manually doing next course away before
  if (!nextCourseBasedOnOrderStatus) {
    const courseIndexToStartFrom = currentOrderingCourseAway ? findIndex(courseGroups, (cg) => cg._key === currentOrderingCourseAway) + 1 : 0;
    newPreviousOrderingCourseAway = currentOrderingCourseAway ? currentOrderingCourseAway : previousOrderingCourseAway;
    for (let index = courseIndexToStartFrom; index < courseGroups.length; index++) {
      const courseGroup = courseGroups[index];
      const courseGroupItems = sentOrderItemsWithCourseGroup.filter((oi) => oi.courseGroupKey === courseGroup._key);
      if (courseGroupItems.length > 0) {
        if (newPreviousOrderingCourseAway) {
          nextCourseKey = courseGroup._key;
          break;
        } else newPreviousOrderingCourseAway = courseGroup._key;
      }
    }
  }
  // Find next course away  based upon the condition where oms has prepared order or not
  else {
    let allItemsPreparedUpToKey = "";
    const courseIndexToStartFrom = currentOrderingCourseAway ? findIndex(courseGroups, (cg) => cg._key === currentOrderingCourseAway) || 0 : 0;

    for (let index = courseIndexToStartFrom; index < courseGroups.length; index++) {
      const courseGroup = courseGroups[index];
      const courseGroupItems = sentOrderItemsWithCourseGroup.filter((oi) => oi.courseGroupKey === courseGroup._key);
      const isAllItemsPrepared = courseGroupItems.length > 0 ? courseGroupItems.every((oi) => oi.orderStatus >= OrderItemStatus.Prepared) : false;
      if (isAllItemsPrepared) {
        allItemsPreparedUpToKey = courseGroup._key;
      }
    }

    const allItemsPreparedUpToIndex = findIndex(courseGroups, (cg) => cg._key === allItemsPreparedUpToKey);
    if (allItemsPreparedUpToIndex > -1) {
      // Find next course group
      for (let index = allItemsPreparedUpToIndex + 1; index < courseGroups.length; index++) {
        const courseGroup = courseGroups[index];
        const courseGroupItems = sentOrderItemsWithCourseGroup.filter((oi) => oi.courseGroupKey === courseGroup._key);
        if (courseGroupItems.length > 0) {
          nextCourseKey = courseGroup._key;
          break;
        } else nextCourseKey = "";
      }

      // Find previous course group
      if (allItemsPreparedUpToIndex > -1) {
        for (let index = allItemsPreparedUpToIndex; index >= 0; index--) {
          const courseGroup = courseGroups[index];
          const courseGroupItems = sentOrderItemsWithCourseGroup.filter((oi) => oi.courseGroupKey === courseGroup._key);
          if (courseGroupItems.length > 0) {
            newPreviousOrderingCourseAway = courseGroup._key;
            break;
          }
        }
      }
    }
    if (!newPreviousOrderingCourseAway) newPreviousOrderingCourseAway = previousOrderingCourseAway;
    if (!nextCourseKey) {
      /** If no next course found and if there is currentOrderingCourse in db then check if all items has been prepared for it and if it has not been prepared and assign that as next course */
      if (!sentOrderItemsWithCourseGroup.filter((oi) => oi.courseGroupKey === currentOrderingCourseAway).every((oi) => oi.orderStatus >= OrderItemStatus.Prepared)) {
        nextCourseKey = currentOrderingCourseAway;
      }
    }
  }

  return [courseGroups, nextCourseKey, newPreviousOrderingCourseAway, courseGroupsWithSentItems] as const;
};

export function getGroupHeadingFromGroupHeadingId(menu: Menu, searchGroupHeadingId: string, menuOptionId?: string) {
  if (!menu || !searchGroupHeadingId) return null;
  let groupHeadings: GroupHeadings = { ...menu.food.groupHeadings, ...menu.beverage.groupHeadings };
  if (menuOptionId) groupHeadings = { ...groupHeadings, ...menu.foodInclusions?.[menuOptionId]?.groupHeadings, ...menu.drinkInclusions?.[menuOptionId]?.groupHeadings };
  let foundGroupHeading = null;

  const getGroupHeading = (groupHeadings, searchGroupHeadingId) => {
    if (foundGroupHeading) return;
    for (const groupHeadingId in groupHeadings) {
      const groupHeading = groupHeadings[groupHeadingId];
      if (groupHeadingId === searchGroupHeadingId) foundGroupHeading = groupHeading;
      getGroupHeading(groupHeading.groupHeadings, searchGroupHeadingId);
    }
  };
  getGroupHeading(groupHeadings, searchGroupHeadingId);
  return foundGroupHeading;
}

/**
 *
 * @param booking
 * @param orders Can pass booking orders of customer orders only.
 * @param productId
 * @param groupHeading
 * @param customerId
 * @returns setting for product marked as limitless
 */
export const getProductOnlyLimitlessSetting = (booking: Booking | FunctionBooking, orders: Orders, productId: string, groupHeading: GroupHeading, customerId: string, excludeOrderingBasketItem: boolean): ProductOnlyLimitlessSetting => {
  let productLimitless = false;
  let productLimitlessQuantityLimit = 0;
  let productLimitlessQuantityInBasket = 0;
  if (!booking) return { productLimitless, productLimitlessQuantityLimit, productLimitlessQuantityInBasket };
  if (groupHeading?.products?.[productId]?.limitless) {
    const orderItems: OrderItem[] = chain(orders)
      .filter((o) => o.customerId === customerId && o.bookingId === booking._key)
      .map((o) => values(o.orderItems).filter((oi) => (excludeOrderingBasketItem ? o.orderSource === OrderSource.Till || oi.orderStatus > OrderItemStatus.InBasket : true)))
      .flatten()
      .value();
    productLimitlessQuantityInBasket = sumBy(
      orderItems.filter((oi) => oi.productId === productId && oi.orderStatus === OrderItemStatus.InBasket && oi.inclusiveProduct),
      "quantity"
    );

    const orderedPaidLimitlessItems = orderItems.filter((oi) => oi.productId === productId && oi.orderStatus > OrderItemStatus.InBasket && !oi.inclusive && !oi.inclusiveProduct);

    if (orderedPaidLimitlessItems.length > 0) {
      productLimitless = true;
      productLimitlessQuantityLimit = sumBy(orderedPaidLimitlessItems, "quantity");
    }
  }
  return { productLimitless, productLimitlessQuantityLimit, productLimitlessQuantityInBasket };
};

export function getGroupHeadingByProductGroupId(groupHeadings: GroupHeadings, productGroupId: string): [string, GroupHeading] {
  const groupHeadingId = findKey(groupHeadings, (gh) => gh.productGroupId === productGroupId);
  if (groupHeadingId) {
    return [groupHeadingId, groupHeadings[groupHeadingId]];
  }
  for (const groupHeadingId1 in groupHeadings) {
    const [gid, gh] = getGroupHeadingByProductGroupId(groupHeadings[groupHeadingId1].groupHeadings, productGroupId);
    if (gid) return [gid, gh];
  }
  return ["", null];
}

export function getGroupHeadingOfProduct(groupHeading: GroupHeading, groupHeadingId: string, productId, onlyFilterLandingPageProduct = false): [string, GroupHeading] {
  if (keys(groupHeading?.products).includes(productId) && (!onlyFilterLandingPageProduct || !!groupHeading?.products?.[productId]?.landingPage)) {
    return [groupHeadingId, groupHeading];
  }
  if (groupHeading) {
    for (const groupHeadingId in groupHeading.groupHeadings) {
      const [gid, gh] = getGroupHeadingOfProduct(groupHeading.groupHeadings[groupHeadingId], groupHeadingId, productId, onlyFilterLandingPageProduct);
      if (gid) return [gid, gh];
    }
  }
  return ["", null];
}

export function filterEmptyGroupHeadingsByMenu(menu: Menu, menuOptionId: string, productsStockLimit?: StockLimitProducts, reduceMenu = false) {
  if (!menu) return null;
  const clonedMenu = cloneDeep(menu) as Menu;
  filterGroupHeadings(clonedMenu.beverage?.groupHeadings, productsStockLimit, reduceMenu);
  filterGroupHeadings(clonedMenu.food?.groupHeadings, productsStockLimit, reduceMenu);
  if (menuOptionId) {
    if (clonedMenu.drinkInclusions?.[menuOptionId]) filterGroupHeadings(clonedMenu.drinkInclusions[menuOptionId].groupHeadings, productsStockLimit, reduceMenu);
    if (clonedMenu.foodInclusions?.[menuOptionId]) filterGroupHeadings(clonedMenu.foodInclusions[menuOptionId].groupHeadings, productsStockLimit, reduceMenu);
  } else {
    for (const menuOptionId in clonedMenu.drinkInclusions) {
      filterGroupHeadings(clonedMenu.drinkInclusions[menuOptionId].groupHeadings, productsStockLimit, reduceMenu);
    }
    for (const menuOptionId in clonedMenu.foodInclusions) {
      filterGroupHeadings(clonedMenu.foodInclusions[menuOptionId].groupHeadings, productsStockLimit, reduceMenu);
    }
  }
  return clonedMenu;
}

function filterGroupSubHeadings(groupHeadings: GroupHeadings) {
  for (const gh in groupHeadings) {
    if (!groupHeadings[gh].isEnableSubheading) {
      delete groupHeadings[gh];
    } else {
      filterGroupSubHeadings(groupHeadings[gh].groupHeadings);
    }
  }
}

export function filterGroupHeadings(groupHeadings: GroupHeadings, stockLimits: StockLimitProducts, reduceMenu = false) {
  for (const gh in groupHeadings) {
    //check if any products under this groupHeading if not delete the groupHeading
    const productIds = [];
    getAllVisibleProductsFromGroupHeading(groupHeadings[gh], productIds, stockLimits, reduceMenu);
    filterGroupSubHeadings(groupHeadings[gh].groupHeadings);
    if (productIds.length === 0) {
      delete groupHeadings[gh];
    } else {
      if (keys(groupHeadings[gh].products).length > 0) {
        groupHeadings[gh].products = chain(groupHeadings[gh].products)
          .pickBy((p) => findKey(p.productSizeIds, (i) => i.enabled === true) !== undefined)
          .pickBy((p) => findKey(p.productSizeIds, (i) => (reduceMenu && !i.notReducedMenu) || !reduceMenu) !== undefined)
          .value();
      } else {
        filterGroupHeadings(groupHeadings[gh].groupHeadings, stockLimits, reduceMenu);
      }
    }
  }
}

export function getAllVisibleProductsFromGroupHeading(groupHeading: GroupHeading, productIds: string[], stockLimits: StockLimitProducts, reduceMenu = false) {
  if (keys(groupHeading.products).length > 0) {
    productIds.push(
      ...chain(groupHeading.products)
        .pickBy((p) => findKey(p.productSizeIds, (i) => i.enabled === true) !== undefined)
        .pickBy((p) => findKey(p.productSizeIds, (i) => (reduceMenu && !i.notReducedMenu) || !reduceMenu) !== undefined)
        .pickBy((p, productId) => findKey(p.productSizeIds, (i, sizeId) => stockLimits?.[productId]?.[sizeId]?.status !== "hide") !== undefined)
        .keys()
        .value()
    );
  } else {
    for (const gh in groupHeading.groupHeadings) {
      getAllVisibleProductsFromGroupHeading(groupHeading.groupHeadings[gh], productIds, stockLimits, reduceMenu);
    }
  }
}

export const getMenu = (courseId: string, menus: Menus) => {
  const menuId = findKey(menus, (m) => m.courses && m.courses[courseId]);
  if (menuId) {
    const course = menus[menuId].courses[courseId];
    if (course) {
      return `${course.name}, ${menus[menuId].name}`;
    }
  }
  return "n/a";
};

export const getMilliSecondsFromInterval = (interval: number) => {
  const today = new Date();
  return today.setHours(0, 0, 0, 0) + interval;
};

/**
 * Returns time diff in milliseconds between booking end time and given offset time in minutes
 * @param { Booking} booking
 * @param { srting } zoneId
 * @param { numner } endTimeOffsetInMinutes
 * @returns
 */
export const getDiffFromBookingEnd = (booking: Booking | FunctionBooking, zoneId: string, endTimeOffsetInMinutes = 0, now?: number): number => {
  const bookingEndTime = moment(Number(booking.intervalId))
    .utc()
    .add(booking.alg.duration - (booking.alg.reset || 0), "minutes")
    .valueOf();
  if (!now) now = getNowByZoneId(zoneId) + (getTodayByZoneId(zoneId) - Number(booking.date)) * 24 * 60 * 60 * 1000;

  return bookingEndTime - now - endTimeOffsetInMinutes * 60 * 1000;
};

/**
 * Get list of all product ids that are on special
 * @param { StockLimitProducts } specialsProducts
 * @param { string } menuId
 * @returns { string[] } List of ids of products that are on special
 */
export function getAllSpecialsProductIdsFromMenu(specialsProducts: StockLimitProducts, menuId: string): string[] {
  const productIds: string[] = [];
  for (const productId in specialsProducts) {
    if (findKey(specialsProducts[productId], (s) => s.menus?.[menuId] !== undefined)) {
      productIds.push(productId);
    }
  }
  return productIds;
}

export const getParsedMenuInclusions = (inclusions: MenuInclusions, products) => {
  const menuInclusions: MenuInclusions = {};
  for (const fixedProductId in inclusions) {
    const product = products.find((p) => p.id === fixedProductId);
    menuInclusions[fixedProductId] = {
      ...inclusions[fixedProductId],
      name: product?.name,
    };
  }

  return menuInclusions;
};

export function getAllMenuProducts(menu: Menu, allProducts: Product[], modifierGroups: ModifierGroups, additionGroups: AdditionGroups, upsellGroups: UpsellGroups): Product[] {
  const products = filter(allProducts, (p) => p.restaurants?.[menu.restaurantId]?.enabled);
  const groupHeadingProducts = getAllGroupHeadingProductsFromMenu(menu);
  const menuOptionIds = uniq([...keys(menu.foodInclusions), ...keys(menu.drinkInclusions)]);

  let menuProducts = deriveMenuOptionProducts(menu, menuOptionIds, groupHeadingProducts, products);
  const upsellAndComboProducts = deriveUpsellAndComboProducts(menuProducts, products);
  menuProducts = uniqBy([...menuProducts, ...upsellAndComboProducts], "id");

  const additionModifiersUpsells = deriveAdditionModifiersUpsells(additionGroups, modifierGroups, upsellGroups, products);
  return uniqBy([...menuProducts, ...additionModifiersUpsells], "id");
}

export function deriveMenuOptionProducts(menu: any, menuOptionIds: string[], groupHeadingProducts: any, products: Product[]): Product[] {
  const menuProducts: Product[] = [];

  forEach(menuOptionIds, (menuOptionId) => {
    assign(groupHeadingProducts, getAllGroupHeadingProductsFromMenu(menu, menuOptionId));
    const product = find(products, { id: menuOptionId });
    if (product) menuProducts.push(product);
  });

  forEach(groupHeadingProducts, (_, productId) => {
    const product = find(products, { id: productId });
    if (product) menuProducts.push(product);
  });

  return menuProducts;
}

export function deriveUpsellAndComboProducts(menuProducts: Product[], allProducts: Product[]): Product[] {
  return flatMap(menuProducts, (product) => flatMap(product?.groupProducts, (comboGroup: ComboGroup[]) => flatMap(comboGroup, (combo: ComboGroup) => filter(map(keys(combo.combinations), (productId) => find(allProducts, { id: productId }))))));
}

export function deriveAdditionModifiersUpsells(additionGroups: AdditionGroups, modifierGroups: ModifierGroups, upsellGroups: UpsellGroups, products: Product[]): Product[] {
  const processGroup = (group: any) => filter(map(group.products || [], (product) => find(products, { id: product.productId })));

  return uniqBy([...flatMap(additionGroups, processGroup), ...flatMap(modifierGroups, processGroup), ...flatMap(upsellGroups, processGroup)], "id");
}

/**
 * get list of GroupHeading Ids from given Menu
 */
export function getAllGroupHeadingIdsFromMenu(menu: Menu): string[] {
  const extractIds = (groupHeadings: GroupHeadings | undefined): string[] => {
    if (!groupHeadings) return [];

    return flatMap(groupHeadings, (heading, id) => [id, ...extractIds(heading.groupHeadings)]);
  };

  const foodIds = extractIds(menu.food?.groupHeadings);
  const beverageIds = extractIds(menu.beverage?.groupHeadings);
  const foodInclusionIds = flatMap(menu.foodInclusions, (inclusion) => extractIds(inclusion.groupHeadings));
  const drinkInclusionIds = flatMap(menu.drinkInclusions, (inclusion) => extractIds(inclusion.groupHeadings));

  return uniq([...foodIds, ...beverageIds, ...foodInclusionIds, ...drinkInclusionIds]);
}

export function getAllMenuHeadingIdsFromGroupHeadings(groupHeadings: GroupHeadings | undefined, result: string[]) {
  if (!groupHeadings) return result;

  Object.values(groupHeadings).forEach((groupHeading) => {
    if (groupHeading.menuHeadingId) {
      result.push(groupHeading.menuHeadingId);
    }
    getAllMenuHeadingIdsFromGroupHeadings(groupHeading.groupHeadings, result);
  });
  return result;
}

/**
 * Get list of all MenuHeading Id from given Menu
 */
export function getAllMenuHeadingIdsFromMenu(menu: Menu): string[] {
  const menuHeadingIds: string[] = [];

  function traverse(current) {
    if (isObject(current as any)) {
      if (current.menuHeadingId) {
        menuHeadingIds.push(current.menuHeadingId);
      }
      forEach(current, (value) => {
        traverse(value);
      });
    } else if (isArray(current)) {
      current.forEach((item) => traverse(item));
    }
  }

  traverse(menu);

  return uniq(menuHeadingIds);
}

/**
 * get list of intervals Ids outside of menu service period for booking
 * @param serviceStartTime
 * @param serviceDuration
 * @param bookingStartTime
 * @param bookingDuration
 * @param intervalUnitInMinutes
 * @returns
 */
export const getIntervalIdsOutsideOfService = (serviceStartTime: number, serviceDuration: number, bookingStartTime: number, bookingDuration?: number, intervalUnitInMinutes = 15) => {
  const outsideOfServiceBuffer = 2 * 60 * 60 * 1000; // 2 hours. To account for scenarios when booking is seated outside of booking start and beyond the duration of booking
  const bookingEndTIme = Number(bookingStartTime) + bookingDuration * 60 * 1000;

  const preServiceStartInterval = serviceStartTime === bookingStartTime ? bookingStartTime : bookingStartTime - outsideOfServiceBuffer;
  const preServiceEndInterval = serviceStartTime || bookingStartTime;
  const preServiceIntervals = serviceStartTime ? getIntervalIdRange(preServiceStartInterval, preServiceEndInterval, intervalUnitInMinutes) : [];

  const postServiceStartInterval = serviceStartTime + serviceDuration * 60 * 1000;
  const postServiceEndInterval = postServiceStartInterval > bookingEndTIme ? bookingEndTIme + outsideOfServiceBuffer : bookingEndTIme;
  const postServiceIntervals = !isNil(serviceDuration) && serviceStartTime ? getIntervalIdRange(postServiceStartInterval, postServiceEndInterval, intervalUnitInMinutes) : [];
  return concat(preServiceIntervals, postServiceIntervals);
};

export function getIntervalIdRange(start: number, end: number, intervalInMinutes = 15) {
  const intervals: number[] = [];
  const { open, close } = getOpeningTimesAdjusted(start, end);
  if (open && close && intervalInMinutes) {
    const step = intervalInMinutes * (1000 * 60);
    let duration = Math.abs(Number(moment.duration(moment.utc(close).diff(moment.utc(open))).valueOf()));
    let currentInterval = open;
    while (duration > 0) {
      intervals.push(currentInterval);
      currentInterval = currentInterval + step;
      duration -= step;
    }
  }
  return intervals;
}

export function getGroupHeadingsFromMenu(menuHeadingType: number, menu: Menu, inclusion: boolean, menuOptionId: string, filterGroupHeadings = true): GroupHeadings {
  let groupHeadings = {};
  const updatedMenu = filterGroupHeadings ? filterEmptyGroupHeadingsByMenu(menu, menuOptionId) : { ...menu };
  if (!updatedMenu) return groupHeadings;
  if (inclusion) {
    if (menuHeadingType === 0 && updatedMenu.foodInclusions?.[menuOptionId]?.groupHeadings) {
      groupHeadings = updatedMenu.foodInclusions?.[menuOptionId]?.groupHeadings;
    } else if (menuHeadingType === 1 && updatedMenu.drinkInclusions?.[menuOptionId]?.groupHeadings) {
      groupHeadings = updatedMenu.drinkInclusions?.[menuOptionId]?.groupHeadings;
    }
  } else {
    if (menuHeadingType === 0 && updatedMenu.food?.groupHeadings) {
      groupHeadings = updatedMenu.food?.groupHeadings;
    } else if (menuHeadingType === 1 && updatedMenu.beverage?.groupHeadings) {
      groupHeadings = updatedMenu.beverage?.groupHeadings;
    }
  }
  //search
  if (menuHeadingType === null) {
    assign(groupHeadings, updatedMenu.food?.groupHeadings, updatedMenu.beverage?.groupHeadings, updatedMenu.foodInclusions?.[menuOptionId]?.groupHeadings, updatedMenu.drinkInclusions?.[menuOptionId]?.groupHeadings);
  }
  return groupHeadings || {};
}

export const shouldUseComboChildItemsSeparately = (orderItem: OrderItem) => {
  return keys(orderItem.comboGroup).length > 0 && !!orderItem.useComboChildItemsAsSeparateForDocket;
};

/**
 * Finds all GroupHeadings where given product belogs.
 */
export function findProductGroupHeadings(productId, menu: Menu, menuOptionId?: string, productsStockLimit?: StockLimitProducts, onlyFilterLandingPageProduct = false): GroupHeadings {
  const matchedGroupHeadings = {};
  const _menu = filterEmptyGroupHeadingsByMenu(menu, menuOptionId, productsStockLimit);
  const groupHeadings = { ..._menu.food?.groupHeadings, ..._menu.beverage?.groupHeadings, ..._menu.foodInclusions?.[menuOptionId]?.groupHeadings, ..._menu.drinkInclusions?.[menuOptionId]?.groupHeadings };
  for (const [groupHeadingId, groupHeading] of Object.entries(groupHeadings)) {
    const [gid, gh] = getGroupHeadingOfProduct(groupHeading as GroupHeading, groupHeadingId, productId, onlyFilterLandingPageProduct);
    if (gid && !isEmpty(gh)) matchedGroupHeadings[gid] = { ...gh, menuHeadingId: (groupHeading as GroupHeading)?.menuHeadingId };
  }
  return matchedGroupHeadings;
}
